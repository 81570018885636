import React, { Component, useEffect, useState } from 'react';

import { useHistory } from '../../queries/reimbursementQuery';
import styled from 'styled-components';
export const StyledHistoryContainer = styled.div`
  padding-top: 10px;
  border-radius: 3px;
  min-height: 100px;
  overflow: auto;
  width: 100%;
`;

export const TimeContainer = styled.div`
  flex-grow: 1;
  font-size: x-small;
  float: right;
  padding-top: 5px;
  padding-right: 5px;
`;
export const HistoryItem = styled.div`
  border: 1px #714FFF solid;
  padding-top: 10px;
  paddint-bottom:2px
  padding-left: 5px;
  padding:5px;
  border-radius: 5px;
  border-top: 1px #714FFF solid;
  overflow: auto;
  :hover {
    background-color: #EBE7FF;
    font-size: 17px;
  }
`;

export default function HistoryContainer(props) {
  let history = [];

  // const [history, setHistory] = useState([]);
  const { requestId } = props;

  const {
    isLoading,
    error,
    data: historyData,
    isFetching,
    refetch: refreshHistory,
  } = useHistory(requestId);

  history =
    historyData && historyData.history
      ? historyData && historyData.history
      : [];

  history =
    historyData && historyData.history
      ? historyData && historyData.history
      : [];

  return isLoading ? (
    isLoading
  ) : (
    <StyledHistoryContainer style={{ width: '500px', paddingTop: '10px' }}>
      {history && history.length > 0 ? (
        history.map((item) => (
          <div className="m-1">
            <HistoryItem key={item.activityId}>
              {item.description} {item.userName ? 'by' : ''} {item.userName}
              {item.meta && `(${item.meta})`}
              {item[0]?.claimUtrNumber &&
                `Claim UTR Number is ${item.claimUtrNumber}`}
              {item.audioFileLink && (
                <a
                  href={item.audioFileLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Link
                </a>
              )}
              <TimeContainer>{item.eventTime}</TimeContainer>
            </HistoryItem>
          </div>
        ))
      ) : (
        <HistoryItem>No Activities Here</HistoryItem>
      )}
    </StyledHistoryContainer>
  );
}
