import React, { useRef, useCallback, memo, useState } from 'react';
import Select from 'react-select';
import styled from 'styled-components';
import DocViewer, { DocViewerRenderers } from 'react-doc-viewer';
import {
  Modal as ReactModal,
  ModalHeader,
  ModalBody,
  Button as ReactModalButton,
  ModalFooter,
} from 'reactstrap';
import { FaPlus, FaTimes } from 'react-icons/fa';
import { GrRotateRight, GrRotateLeft } from 'react-icons/gr';
import { BiZoomIn, BiZoomOut } from 'react-icons/bi';
import { tr } from 'date-fns/locale';
import DocumentViewer from '../../containers/common/DocumentViewer';
const Button = styled.button`
  background: #ffffff;
  border: 1px solid #714fff;
  border-radius: 6px;
  padding: 10px 16px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
`;
const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 10px;
  background-color: #f0f0f0;
  position: relative;
  z-index: 1;
`;
const ActionDiv = styled.div`
  position: relative;
  z-index: 1;
`;
const Title = styled.p`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.02em;
  color: #714fff;
`;
const Plus = styled(FaPlus)`
font-size: 1.2rem;
  font-weight: normal;
  color : #714FFF;
  padding-left 1px;
`;
const header = (value) => {
  return (
    <h2
      style={{
        color: 'white',
        fontFamily: 'Montserrat',
        fontSize: '18px',
      }}
    >
      {value}
    </h2>
  );
};

const RightRotate = styled(GrRotateRight)`
  font-size: 1.5rem;
  color: #fff;
  padding: 2px;
  border-radius: 50%;
  border: 1px solid #714fff;
  background-color: #714fff;
`;
const LeftRotate = styled(GrRotateLeft)`
  font-size: 1.5rem;
  color: #fff;
  padding: 2px;
  border-radius: 50%;
  border: 1px solid #714fff;
  background-color: #714fff;
`;

const ZoomIn = styled(BiZoomIn)`
  font-size: 1.7rem;
  padding: 2px;
  color: #fff;
  border-radius: 50%;
  border: 1px solid #714fff;
  background-color: #714fff;
`;
const ZoomOut = styled(BiZoomOut)`
  font-size: 1.7rem;
  color: #fff;
  padding: 2px;
  border-radius: 50%;
  border: 1px solid #714fff;
  background-color: #714fff;
`;
export default function ActionsContainer({
  docs,
  callUser,
  requestCopyClaim,
  requestDeleteClaim,
  onInvoiceFileAdded,
  addInvoiceFile,
  onPrescriptionFileAdded,
  addPrescriptionFile,
  onMerFileAdded,
  addMerFile,
  requestDocumentsDeficient,
  reimbursementPrePostRequestsData,
  isCriticalIllness,
  setPrePostModal,
  toggleIsCriticalIllness,
  addPrescription,
  reimbursementDetail,
  prescriptionModalIsOpen,
  closePrescriptionAddModal,
  prescriptions,
  renderPrescription,
  submitForDigitization,
  selectedPrescriptionFiles,
  digitizing,
  clarificationNeeded,
  clarificationResolution,
  brokerId,
  markClaimSuspicious,
}) {
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(100);
  const handleZoomIn = () => {
    setZoom(Math.min(200, zoom + 10)); // Increase zoom, limit to 200%
  };

  const handleZoomOut = () => {
    setZoom(Math.max(10, zoom - 10)); // Decrease zoom, limit to 10%
  };

  const handleRotateLeft = () => {
    setRotation((rotation - 90) % 360); // Rotate left by 90 degrees
  };

  const handleRotateRight = (docs) => {
    setRotation((rotation + 90) % 360); // Rotate right by 90 degrees
  };

  return (
    <>
      {reimbursementDetail?.comments && (
        <div className="flex flex-wrap mt-2 mb-2">
          <h3 style={{ color: '#714fff', fontSize: '14px', fontWeight: '600' }}>
            User Comment:
          </h3>
          <h3 className="ml-2" style={{ fontSize: '14px', fontWeight: '500' }}>
            {reimbursementDetail?.comments}{' '}
          </h3>
        </div>
      )}

      <div className="" style={{ position: 'relative', zIndex: 1 }}>
        <ButtonContainer>
          <button className="flex flex-row mr-2 " onClick={handleRotateLeft}>
            <LeftRotate />
          </button>
          <button className="flex flex-row mr-2" onClick={handleZoomIn}>
            <ZoomIn />
          </button>
          <button className="flex flex-row mr-2" onClick={handleZoomOut}>
            <ZoomOut />
          </button>

          <button className="" onClick={handleRotateRight}>
            <RightRotate />
          </button>
        </ButtonContainer>

        <DocViewer
          pluginRenderers={DocViewerRenderers}
          documents={docs}
          config={{
            header: {
              disableHeader: false,
              disableFileName: true,
            },
          }}
          style={{
            transform: `rotate(${rotation}deg) scale(${zoom / 100})`,
            backgroundColor: 'white',
          }}
          // style={{ backgroundColor: 'white' }}
          theme={{
            primary: '#714FFF',
            secondary: '#714FFF',
            tertiary: '#5296d899',
            text_primary: '#ffffff',
            text_secondary: '#5296d8',
            text_tertiary: '#00000099',
            disableThemeScrollbar: false,
            backgroundColor: 'white',
          }}
        />
        {/* <DocumentViewer
          
          documents={docs}
        ></DocumentViewer> */}
      </div>
      {brokerId && brokerId ? (
        ''
      ) : (
        <>
          <ActionDiv>
            <h3
              className="mt-2 ml-2"
              style={{
                fontFamily: 'Montserrat',
                color: '#714FFF',
                fontWeight: 'bold',
                lineHeight: '22px',
                fontSize: '16px',
              }}
            >
              Available Actions
            </h3>
            <div className="flex mt-1">
              <Button
                className="ml-2 hover:bg-[#714FFF] hover:text-white"
                onClick={callUser}
              >
                Call User
              </Button>
              {reimbursementDetail?.isScanPay ? (
                ''
              ) : (
                <>
                  <Button
                    className="ml-2 hover:bg-[#714FFF] hover:text-white"
                    onClick={requestCopyClaim}
                  >
                    Copy Claim
                  </Button>

                  <Button
                    className="ml-2 hover:bg-[#714FFF] hover:text-white"
                    onClick={requestDeleteClaim}
                  >
                    Delete Claim
                  </Button>
                </>
              )}

              <input
                type="file"
                id="add-invoice-input"
                accept=".png,.jpg,.jpeg,.pdf"
                style={{ display: 'none' }}
                onChange={onInvoiceFileAdded}
              />
              <Button
                className="ml-2 hover:bg-[#714FFF] hover:text-white"
                onClick={addInvoiceFile}
              >
                Add Invoice
              </Button>
            </div>
            <div className="flex mt-4">
              <input
                type="file"
                id="add-prescription-input"
                accept=".png,.jpg,.jpeg,.pdf"
                style={{ display: 'none' }}
                onChange={onPrescriptionFileAdded}
              />
              <Button
                className="ml-2 hover:bg-[#714FFF] hover:text-white"
                onClick={addPrescriptionFile}
              >
                Add Prescription
              </Button>
              <input
                type="file"
                id="add-mer-input"
                accept=".png,.jpg,.jpeg,.pdf"
                style={{ display: 'none' }}
                onChange={onMerFileAdded}
              />
              <Button
                className="ml-2 hover:bg-[#714FFF] hover:text-white"
                onClick={addMerFile}
              >
                Add Lab Reports
              </Button>
              <Button
                className="ml-2 hover:bg-[#714FFF] hover:text-white"
                onClick={requestDocumentsDeficient}
                // disabled={
                //   reimbursementDetail.requestStatus === 'documents-verified' ||
                //   reimbursementDetail.requestStatus === 'approved'
                // }
              >
                Documents deficient
              </Button>
              {reimbursementPrePostRequestsData &&
              reimbursementPrePostRequestsData.length > 0 ? (
                <Button
                  className="ml-2 hover:bg-[#714FFF] hover:text-white"
                  onClick={() => {
                    setPrePostModal(true);
                  }}
                >
                  {' '}
                  Flagged Claims
                </Button>
              ) : null}
            </div>
            <div className="flex mt-2">
              {reimbursementDetail.subStatus !== 'clarification-need' ? (
                <Button
                  className="ml-2 hover:bg-[#714FFF] hover:text-white"
                  onClick={clarificationNeeded}
                >
                  {' '}
                  Clarification Needed
                </Button>
              ) : (
                ''
              )}
              {reimbursementDetail.subStatus === 'clarification-needed' ? (
                <Button
                  className="ml-2 hover:bg-[#714FFF] hover:text-white"
                  onClick={clarificationResolution}
                >
                  {' '}
                  Resolve Clarification
                </Button>
              ) : (
                ''
              )}
              {!reimbursementDetail.isSuspicious ? (
                <Button
                  className="ml-2 hover:bg-[#714FFF] hover:text-white"
                  onClick={markClaimSuspicious}
                >
                  {' '}
                  Mark Suspicious
                </Button>
              ) : (
                ''
              )}
            </div>
          </ActionDiv>
        </>
      )}

      {/* <div className='flex m-4'>
                <p className='mr-4 text-[#714FFF] font-bold mt-2'>
                    Critical Illness
                </p>
                <label for="default-toggle" className="inline-flex relative items-center cursor-pointer mt-2 ml-3">

                    <input type="checkbox"
                        checked={isCriticalIllness}
                        id="default-toggle"
                        className="sr-only peer"
                        onChange={toggleIsCriticalIllness}
                    />
                    <div className="w-11 h-6 bg-purple-200  rounded-full peer dark:bg-white-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-white-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-white-600 peer-checked:bg-[#714FFF]"></div>

                </label>
            </div> */}

      {/* <div className='m-2 h-20'>
                <Title>
                    How many Unique Prescriptions are there in this Claim?
                </Title>

                <Button
                    className='mt-4 flex hover:bg-[#714FFF] hover:text-white'
                    onClick={addPrescription}
                    disabled={!reimbursementDetail.prescriptionLink}
                >
                    <Plus className='mr-2'></Plus>
                    Add Prescription
                </Button>
                <ReactModal
                    isOpen={prescriptionModalIsOpen}
                    toggle={closePrescriptionAddModal}
                    style={{ borderRadius: '6px' }}
                >
                    <ModalHeader
                        style={{ backgroundColor: '#714FFF', }}
                        toggle={closePrescriptionAddModal}
                    >
                        {header('Add Prescription And Invoice')}

                    </ModalHeader>

                    <ModalBody>
                        {prescriptions.map(renderPrescription)}
                    </ModalBody>

                    <ModalFooter>


                    </ModalFooter>
                    <div className='form-group mb-2 float-right'>
                        <button
                            className=" btn text-white font-semibold bg-[#714FFF] float-right mb-2 mr-2
                                             border-[#714FFF] 
                                                 rounded flex"
                            onClick={submitForDigitization}
                            disabled={
                                selectedPrescriptionFiles.length === 0 || digitizing
                            }
                        >
                            Submit Prescriptions for digitization
                        </button>
                    </div>
                </ReactModal>

            </div> */}
    </>
  );
}
