const config = {
  development: {
    pubnubPublishKey: 'pub-c-b8b14417-cff1-4e64-aa43-b41a777352cf',
    pubnubSubscribeKey: 'sub-c-42db9ed2-315c-11e6-9327-02ee2ddab7fe',
    rootUrl: '.samuraijack.xyz',
    //apiBaseUrl: 'https://api.samuraijack.xyz/latios',
    baseUrl: 'http://localhost:5001',
    //baseUrl: 'https://api.samuraijack.xyz/latios',
    pharmacyUrl: 'https://retail-stage.getvisitapp.net/absol',
    googleMapsApiKey: 'AIzaSyCOh6iK7nfOvJrfpG3RJsNzyJoJtSl3OXc',
  },

  staging: {
    pubnubPublishKey: 'pub-c-b8b14417-cff1-4e64-aa43-b41a777352cf',
    pubnubSubscribeKey: 'sub-c-42db9ed2-315c-11e6-9327-02ee2ddab7fe',
    rootUrl: '.samuraijack.xyz',
    //baseUrl: 'https://api.samuraijack.xyz/v3',
    //baseUrl: 'https://api.samuraijack.xyz/latios',
    //baseUrl: 'https://api.getvisitapp.xyz/ares',
    // baseUrl: 'https://api.samuraijack.xyz/westeros',
    baseUrl: 'https://api.samuraijack.xyz/gringots',
    pharmacyUrl: 'https://retail-stage.getvisitapp.net/absol',
    googleMapsApiKey: 'AIzaSyCOh6iK7nfOvJrfpG3RJsNzyJoJtSl3OXc',
  },
  production: {
    pubnubPublishKey: 'pub-c-fb8ac7e3-75bf-4396-bf14-77215f8b9419',
    pubnubSubscribeKey: 'sub-c-78deef00-680f-11e5-a57f-0619f8945a4f',
    rootUrl: '.getvisitapp.com',
    baseUrl: 'https://api.getvisitapp.com/v3',
    pharmacyUrl: 'https://api.getvisitapp.com/pharmacy',
    googleMapsApiKey: 'AIzaSyCOh6iK7nfOvJrfpG3RJsNzyJoJtSl3OXc',
  },
  uat: {
    pubnubPublishKey: 'pub-c-fb8ac7e3-75bf-4396-bf14-77215f8b9419',
    pubnubSubscribeKey: 'sub-c-78deef00-680f-11e5-a57f-0619f8945a4f',
    rootUrl: '.getvisitapp.com',
    baseUrl: 'https://uat-api.getvisitapp.net/backend',
    pharmacyUrl: 'https://uat-api.getvisitapp.net/pharmacy',
    googleMapsApiKey: 'AIzaSyCOh6iK7nfOvJrfpG3RJsNzyJoJtSl3OXc',
  },
};

module.exports =
  config[process.env.REACT_APP_BUILD_ENV || process.env.NODE_ENV];
