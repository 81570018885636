import React, { useRef, useCallback, memo, useState } from 'react';
import moment from 'moment';
import { components } from 'react-select';
import styled from 'styled-components';
import ShowIcon from '../../assets/images/show.svg';
import DeleteIcon from '../../assets/images/delete.svg';
import AsyncSelect from 'react-select/async';
import { SingleDatePicker } from 'react-dates';
import { debounce } from '../../utils/debounce';
import { FaPrescription, FaFileInvoice } from 'react-icons/fa';
import LabIcon from '../../assets/images/labTestTube.svg';
import { TbTestPipe } from 'react-icons/tb';

import {
  getLabsPartner,
  addNonEmpanelLabsPartner,
  getCenters,
  getPhPartner,
} from '../../services/reimbursementClaims';
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  FormGroup,
  Label,
  Input,
} from 'reactstrap';
const Title = styled.p`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.02em;
  color: #714fff;
`;
const LogoContainer = styled.img`
  width: 23px;
  height: 23px;
`;
const InputBox = styled.input`
  width: 100%;
  padding: 24px;
  height: 3rem;
  border: 1px solid #714fff;
  border-radius: 6px;
  color: 'red';
`;
export const TimeContainer = styled.div`
  flex-grow: 1;
  font-size: x-small;
  float: right;
  padding-top: 5px;
  padding-right: 5px;
`;

const InputBoxForPinCode = styled.input`
  padding: 8px;
  border: 2px solid #b9a7fe;
  border-radius: 4px;
  width: 100%;
  font-size: 15px;
  color: #555;
  outline: none;
  &:focus {
    border-color: #b9a7fe;
  }
`;
const ModalHeaderHeading = styled.h2`
  color: #714fff;
  font-size: 18px;
  font-family: Montserrat;
  font-weight: 600;
`;

const Text = styled.p`
  margin-left: 10px;
  font-family: Montserrat;
  font-size: 0.75rem;
  text-align: center;
`;
const BoldText = styled(Text)`
  font-family: Montserrat;
  font-size: 1rem;
`;

const customStylesForMerchant = {
  control: (provided, state) => ({
    ...provided,
    border: '1px solid #714FFF',
    width: 'full',
    borderRadius: '6px',
    borderColor: state.isFocused ? '#714FFF' : '#714FFF', // change border color based on focus state
    '&:hover': { borderColor: '#714FFF' }, // change border color on hover
  }),
};

export default function ReimbursementInvoiceDetail({
  claimData,
  confirmDeleteFile,
  invoiceName,
  onInvoiceItemChanged,
  invoiceDate,
  invoiceDateInputFocused,
  setInvoiceDateInputFocused,
  invoiceNumber,
  shiftDoc,
  OnInvoiceDateChange,
  isCriticalIllness,
  toggleIsCriticalIllness,
  invoiceTeleNumber,
  maternityOnChange,
  isMaternity,
  blacklistedWarning,
  onMerchantNameChange,
  showMerchantSelectOption,
  otherMerchantNameModal,
  onMerchantNameChangeNonEmpanel,
  otherModalDisabled,
  OnSetNonEmpanelCenterId,
  merchantValue,
  searchType,
  changeDocumentType,
}) {
  const [pincode, setPincode] = useState('');
  const prescriptionArr = claimData?.reimbursementDocuments?.filter(
    (data) => data.type === 'prescription'
  );
  const invoiceArr = claimData?.reimbursementDocuments?.filter(
    (data) => data.type === 'invoice'
  );
  const merArr = claimData?.reimbursementDocuments?.filter(
    (data) => data.type === 'mer'
  );
  const labReportArr = claimData?.reimbursementDocuments?.filter(
    (data) => data.type === 'lab_report'
  );
  const selectedMerchantValue = merchantValue
    ? { value: merchantValue, label: merchantValue }
    : null;

  const renderAddress = (d) => {
    const address = [d.address, d.city, d.state, d.pincode]
      .filter(Boolean)
      .join(', ');
    return address;
  };

  const getLabsPartnerInfo = (input) => {
    return getLabsPartner(input)
      .then((result) => {
        console.log({ result }, 'getDoctors result');
        result.forEach((d) => {
          d.label = d.name;
          d.value = d.id;
          d.data = {
            address: `${renderAddress(d)}`,
          };
        });
        result.push({
          label: 'Enter Merchant Name',
          value: 'other',
          data: {
            address: '',
          },
        });
        return result;
      })
      .catch((err) => {
        console.log({ err });
        return [];
      });
  };

  const getVaccinationCentersInfo = (input) => {
    const isReimbursement = true;
    return getCenters(input, isReimbursement)
      .then((result) => {
        console.log({ result }, 'center result...');
        result.forEach((cen) => {
          cen.label = cen.title;
          cen.data = {
            address: cen.address,
          };
          cen.value = cen.centerId;
        });
        result.push({
          label: 'Enter Center Details',
          value: 'other',
          data: {
            address: '',
          },
        });
        return result;
      })
      .catch((err) => {
        console.log({ err });
        return [];
      });
  };

  const getPhPartnerInfo = (input) => {
    return getPhPartner(input)
      .then((result) => {
        console.log({ result }, 'ph center result...');
        result.forEach((cen) => {
          cen.logo = cen.partnerLogoURL;
          cen.label = cen.partnerName;
          cen.data = {
            address: `${renderAddress(cen)}`,
          };
          cen.value = cen.partnerId;
        });
        result.push({
          label: 'Enter Center Details',
          value: 'other',
          data: {
            address: '',
          },
        });
        return result;
      })
      .catch((err) => {
        console.log({ err });
        return [];
      });
  };

  const debouncedGetLabsPartner = debounce((input, resolve) => {
    getLabsPartnerInfo(input).then(resolve);
  }, 500);

  const debouncedGetPhPartner = debounce((input, resolve) => {
    getPhPartnerInfo(input).then(resolve);
  }, 500);

  const debouncedGetVaccinationCenter = debounce((input, resolve) => {
    getVaccinationCentersInfo(input).then(resolve);
  }, 500);

  const promiseLabsPartnerOptions = (input) => {
    if (searchType === 'vaccination') {
      return new Promise((resolve) => {
        debouncedGetVaccinationCenter(input.toLowerCase(), resolve);
      });
    } else if (searchType === 'medicine') {
      return new Promise((resolve) => {
        debouncedGetPhPartner(input.toLowerCase(), resolve);
      });
    } else {
      return new Promise((resolve) => {
        debouncedGetLabsPartner(input.toLowerCase(), resolve);
      });
    }
  };

  const CustomOptionWithImg = (props) => {
    const defaultLogo =
      'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==';
    return (
      <div
        {...props.innerProps}
        style={{
          display: 'flex',
          alignItems: 'center',
          padding: '10px',
          cursor: 'pointer',
          borderRadius: '4px',
          backgroundColor: props.isFocused ? '#EAE7FF' : 'transparent',
          flexDirection: 'column', // Align items in a column
          textAlign: 'left', // Align text to the left
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
          <img
            src={props.data.logo || defaultLogo}
            alt={props.data.label}
            style={{
              width: '30px',
              height: '30px',
              borderRadius: '50%',
              marginRight: '10px',
            }}
          />
          <span>{props.label}</span>
        </div>
        <div
          style={{
            display: 'flex',
            marginTop: '5px',
            width: '100%',
            alignItems: 'center',
          }}
        >
          <Text fontSize="10px" lineHeight="10px" color="#6A57F5">
            {props.data.data.address}
          </Text>
        </div>
      </div>
    );
  };

  const fetchZipCode = (e) => {
    const pinRegex = /^\d{6}$/;
    const validatePinCode = pinRegex.test(e.target.value);
    if (validatePinCode) {
      return setPincode(e.target.value);
    }
    setPincode('');
  };

  const addNonEmpanelCenter = async () => {
    const originalString = invoiceName;
    const stringWithoutSpaces = originalString.replace(/^\s+/, '');
    const source =
      searchType === 'vaccination'
        ? 'vaccination'
        : searchType === 'medicine'
        ? 'medicine'
        : 'lab';
    const body = { pincode, merchantName: stringWithoutSpaces, source };
    await addNonEmpanelLabsPartner(body)
      .then((res) => {
        if (res.message === 'success') {
          OnSetNonEmpanelCenterId(res.result);
          otherModalDisabled();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="flex p-0">
      <div className="basis-3/5">
        <div className="bg-white h-100 rounded  p-2 mr-1">
          <div className="flex space-x-8">
            <div className="flex flex-row">
              <Title>Uploaded Prescriptions and Invoices</Title>
            </div>
            <div className="flex flex-col">
              <Title>Document Type</Title>
              <div className="flex mt-1">
                <label
                  style={{
                    borderRadius: '50%',
                    color: 'white',
                    fontSize: '9px',
                    border: '1px solid #EC6519',
                    fontWeight: 600,
                    width: '14px',
                    height: '14px',
                    backgroundColor: '#EC6519',
                    textAlign: 'center',
                    bottom: '2px',
                  }}
                >
                  i
                </label>
                <label
                  className="ml-1"
                  style={{
                    color: '#EC6519',
                    fontSize: '11px',
                    fontFamily: 'Montserrat',
                    fontWeight: 600,
                  }}
                >
                  Update document type if incorrect.
                </label>
              </div>
            </div>
          </div>
          {prescriptionArr &&
            prescriptionArr.map((data, idx) => {
              const link = data.url;
              const documentId = data.documentId;
              const { documentCodiId } = data;

              const uploadedAt = moment(data.uploadedOn).format(
                'Do MMM, hh:mm a'
              );
              return (
                <div key={idx} className="flex">
                  <div
                    className=" m-2 pl-1 flex border-2 rounded  border-[#714FFF]"
                    style={{ width: '280px' }}
                  >
                    <a
                      className="flex ml-2  "
                      style={{ color: 'black' }}
                      rel="noopener noreferrer"
                      target="_blank"
                      href={link}
                    >
                      {` Prescription${idx + 1}_${link.split('.').pop()}`}
                      <TimeContainer className="ml-1">
                        {uploadedAt}
                      </TimeContainer>
                    </a>
                    <button
                      className="flex ml-auto mr-1"
                      onClick={shiftDoc(link)}
                    >
                      <LogoContainer src={ShowIcon} alt="show Icon" />
                    </button>
                  </div>

                  {/* <div className="flex border-2 rounded  border-[#714FFF] m-2"> */}
                  <div className="flex mt-3">
                    <LogoContainer
                      src={DeleteIcon}
                      alt=" delete logo"
                      onClick={confirmDeleteFile(
                        `Prescription file ${idx + 1}`,
                        'prescription',
                        link
                      )}
                    />
                  </div>
                  <div
                    className="flex  ml-3 mt-2"
                    style={{
                      border: '1px solid #714FFF',
                      borderRadius: '6px',
                      padding: '2px',
                    }}
                  >
                    {/* Prescription Section */}
                    <div className="flex flex-col  mx-2">
                      <FaPrescription
                        className="custom-icon"
                        style={{
                          fontSize: '22px',
                          borderRadius: '30%',
                          border: '1px solid #714FFF',
                          padding: '2px',
                          opacity: documentCodiId ? 0.5 : 1,
                          cursor: documentCodiId ? 'not-allowed' : 'pointer',
                          pointerEvents: documentCodiId ? 'none' : 'auto',
                        }}
                        onClick={() => {
                          if (!documentCodiId) {
                            changeDocumentType('prescription', documentId);
                          }
                        }}
                      />
                      <span>Rx</span>
                    </div>

                    {/* Vertical Line */}
                    {/* <div
                      style={{
                        height: '60px', // Adjust height to fit the tallest icon with text
                        width: '1px',
                        backgroundColor: '#714FFF',
                        margin: '0 5px',
                      }}
                    /> */}

                    {/* Invoice Section */}
                    <div className="flex flex-col items-center mx-2">
                      <FaFileInvoice
                        className="custom-icon"
                        style={{
                          fontSize: '22px',
                          borderRadius: '30%',
                          border: '1px solid #714FFF',
                          padding: '2px',
                          opacity: documentCodiId ? 0.5 : 1,
                          cursor: documentCodiId ? 'not-allowed' : 'pointer',
                          pointerEvents: documentCodiId ? 'none' : 'auto',
                        }}
                        onClick={() => {
                          if (!documentCodiId) {
                            changeDocumentType('invoice', documentId);
                          }
                        }}
                      />
                      <span>Invoice</span>
                    </div>

                    {/* Vertical Line */}
                    {/* <div
                      style={{
                        height: '60px', // Adjust height to fit the tallest icon with text
                        width: '1px',
                        backgroundColor: '#714FFF',
                        margin: '0 5px',
                      }}
                    /> */}

                    {/* Lab Report Section */}
                    <div className="flex flex-col items-center mx-2">
                      <TbTestPipe
                        className="custom-icon"
                        style={{
                          fontSize: '22px',
                          borderRadius: '30%',
                          border: '1px solid #714FFF',
                          padding: '2px',
                          opacity: documentCodiId ? 0.5 : 1,
                          cursor: documentCodiId ? 'not-allowed' : 'pointer',
                          pointerEvents: documentCodiId ? 'none' : 'auto',
                        }}
                        onClick={() => {
                          if (!documentCodiId) {
                            changeDocumentType('lab_report', documentId);
                          }
                        }}
                      />

                      <span>Lab Report</span>
                    </div>
                  </div>
                </div>
              );
            })}
          {invoiceArr &&
            invoiceArr.map((data, idx) => {
              const link = data.url;
              const documentId = data.documentId;
              const { documentCodiId } = data;
              const uploadedAt = moment(data.uploadedOn).format(
                'Do MMM, hh:mm a'
              );
              return (
                <div key={idx} className="flex">
                  <div
                    className=" m-2 pl-0.5 flex border-2 rounded  border-[#714FFF]"
                    style={{ width: '280px' }}
                  >
                    <a
                      className="flex ml-2  "
                      style={{ color: 'black' }}
                      rel="noopener noreferrer"
                      target="_blank"
                      href={link}
                    >
                      {` Invoice${idx + 1}_${link.split('.').pop()}`}
                      <TimeContainer className="ml-1">
                        {uploadedAt}
                      </TimeContainer>
                    </a>

                    <button
                      className=" flex ml-auto mr-1"
                      onClick={shiftDoc(link)}
                    >
                      <LogoContainer src={ShowIcon} alt="show Icon" />
                    </button>
                  </div>

                  <div className="flex  mt-3">
                    <LogoContainer
                      src={DeleteIcon}
                      alt=" delete logo"
                      onClick={confirmDeleteFile(
                        `Invoice ${idx + 1}`,
                        'invoice',
                        link
                      )}
                    />
                  </div>

                  <div
                    className="flex  ml-3 mt-2"
                    style={{
                      border: '1px solid #714FFF',
                      borderRadius: '6px',
                      padding: '2px',
                    }}
                  >
                    {/* Prescription Section */}
                    <div className="flex flex-col  mx-2">
                      <FaPrescription
                        className="custom-icon"
                        style={{
                          fontSize: '22px',
                          borderRadius: '30%',
                          border: '1px solid #714FFF',
                          padding: '2px',
                          opacity: documentCodiId ? 0.5 : 1,
                          cursor: documentCodiId ? 'not-allowed' : 'pointer',
                          pointerEvents: documentCodiId ? 'none' : 'auto',
                        }}
                        onClick={() => {
                          if (!documentCodiId) {
                            changeDocumentType('prescription', documentId);
                          }
                        }}
                      />
                      <span>Rx</span>
                    </div>

                    {/* Vertical Line */}
                    {/* <div
                      style={{
                        height: '60px', // Adjust height to fit the tallest icon with text
                        width: '1px',
                        backgroundColor: '#714FFF',
                        margin: '0 5px',
                      }}
                    /> */}

                    {/* Invoice Section */}
                    <div className="flex flex-col items-center mx-2">
                      <FaFileInvoice
                        className="custom-icon"
                        style={{
                          fontSize: '22px',
                          borderRadius: '30%',
                          border: '1px solid #714FFF',
                          padding: '2px',
                          opacity: documentCodiId ? 0.5 : 1,
                          cursor: documentCodiId ? 'not-allowed' : 'pointer',
                          pointerEvents: documentCodiId ? 'none' : 'auto',
                        }}
                        onClick={() => {
                          if (!documentCodiId) {
                            changeDocumentType('invoice', documentId);
                          }
                        }}
                      />
                      <span>Invoice</span>
                    </div>

                    {/* Vertical Line */}
                    {/* <div
                      style={{
                        height: '60px', // Adjust height to fit the tallest icon with text
                        width: '1px',
                        backgroundColor: '#714FFF',
                        margin: '0 5px',
                      }}
                    /> */}

                    {/* Lab Report Section */}
                    <div className="flex flex-col items-center mx-2">
                      <TbTestPipe
                        className="custom-icon"
                        style={{
                          fontSize: '22px',
                          borderRadius: '30%',
                          border: '1px solid #714FFF',
                          padding: '2px',
                          opacity: documentCodiId ? 0.5 : 1,
                          cursor: documentCodiId ? 'not-allowed' : 'pointer',
                          pointerEvents: documentCodiId ? 'none' : 'auto',
                        }}
                        onClick={() => {
                          if (!documentCodiId) {
                            changeDocumentType('lab_report', documentId);
                          }
                        }}
                      />
                      <span>Lab Report</span>
                    </div>
                  </div>
                </div>
              );
            })}

          {merArr &&
            merArr.map((data, idx) => {
              const link = data.url;
              const documentId = data.documentId;
              const { documentCodiId } = data;
              const uploadedAt = moment(data.uploadedOn).format(
                'Do MMM, hh:mm a'
              );
              return (
                <div key={idx} className="flex">
                  <div
                    className="m-2 pl-1.5 flex border-2 rounded  border-[#714FFF]"
                    style={{ width: '280px' }}
                  >
                    <a
                      className="flex ml-2"
                      style={{ color: 'black' }}
                      rel="noopener noreferrer"
                      target="_blank"
                      href={link}
                    >
                      {`MER${idx + 1}_${link.split('.').pop()}`}
                      <TimeContainer className="ml-1">
                        {uploadedAt}
                      </TimeContainer>
                    </a>

                    <button
                      className=" flex ml-auto mr-1"
                      onClick={shiftDoc(link)}
                    >
                      <LogoContainer src={ShowIcon} alt="show Icon" />
                    </button>
                  </div>

                  {/* <div className="flex mt-3">
                    <LogoContainer
                      src={DeleteIcon}
                      alt=" delete logo"
                      onClick={confirmDeleteFile(
                        `Invoice ${idx + 1}`,
                        'lab_report',
                        link
                      )}
                    />
                  </div> */}

                  <div
                    className="flex  ml-12 mt-2"
                    style={{
                      border: '1px solid #714FFF',
                      borderRadius: '6px',
                      padding: '2px',
                    }}
                  >
                    {/* Prescription Section */}
                    <div className="flex flex-col  mx-2">
                      <FaPrescription
                        className="custom-icon"
                        style={{
                          fontSize: '22px',
                          borderRadius: '30%',
                          border: '1px solid #714FFF',
                          padding: '2px',
                          opacity: documentCodiId ? 0.5 : 1,
                          cursor: documentCodiId ? 'not-allowed' : 'pointer',
                          pointerEvents: documentCodiId ? 'none' : 'auto',
                        }}
                        onClick={() => {
                          if (!documentCodiId) {
                            changeDocumentType('prescription', documentId);
                          }
                        }}
                      />
                      <span>Rx</span>
                    </div>

                    {/* Vertical Line */}
                    {/* <div
                      style={{
                        height: '60px', // Adjust height to fit the tallest icon with text
                        width: '1px',
                        backgroundColor: '#714FFF',
                        margin: '0 5px',
                      }}
                    /> */}

                    {/* Invoice Section */}
                    <div className="flex flex-col items-center mx-2">
                      <FaFileInvoice
                        className="custom-icon"
                        style={{
                          fontSize: '22px',
                          borderRadius: '30%',
                          border: '1px solid #714FFF',
                          padding: '2px',
                          opacity: documentCodiId ? 0.5 : 1,
                          cursor: documentCodiId ? 'not-allowed' : 'pointer',
                          pointerEvents: documentCodiId ? 'none' : 'auto',
                        }}
                        onClick={() => {
                          if (!documentCodiId) {
                            changeDocumentType('invoice', documentId);
                          }
                        }}
                      />
                      <span>Invoice</span>
                    </div>

                    {/* Vertical Line */}
                    {/* <div
                      style={{
                        height: '60px', // Adjust height to fit the tallest icon with text
                        width: '1px',
                        backgroundColor: '#714FFF',
                        margin: '0 5px',
                      }}
                    /> */}

                    {/* Lab Report Section */}
                    <div className="flex flex-col items-center mx-2">
                      <TbTestPipe
                        className="custom-icon"
                        style={{
                          fontSize: '22px',
                          borderRadius: '30%',
                          border: '1px solid #714FFF',
                          padding: '2px',
                          opacity: documentCodiId ? 0.5 : 1,
                          cursor: documentCodiId ? 'not-allowed' : 'pointer',
                          pointerEvents: documentCodiId ? 'none' : 'auto',
                        }}
                        onClick={() => {
                          if (!documentCodiId) {
                            changeDocumentType('lab_report', documentId);
                          }
                        }}
                      />
                      <span>Lab Report</span>
                    </div>
                  </div>
                </div>
              );
            })}
          {labReportArr &&
            labReportArr.map((data, idx) => {
              const link = data.url;
              const documentId = data.documentId;
              const { documentCodiId } = data;
              const uploadedAt = moment(data.uploadedOn).format(
                'Do MMM, hh:mm a'
              );
              return (
                <div key={idx} className="flex">
                  <div
                    className=" m-2 pl-1.5 flex border-2 rounded  border-[#714FFF]"
                    style={{ width: '280px' }}
                  >
                    <a
                      className="flex ml-2"
                      style={{ color: 'black' }}
                      rel="noopener noreferrer"
                      target="_blank"
                      href={link}
                    >
                      {`Lab_Report${idx + 1}_${link.split('.').pop()}`}
                      <TimeContainer className="ml-1">
                        {uploadedAt}
                      </TimeContainer>
                    </a>

                    <button
                      className=" flex ml-auto mr-1"
                      onClick={shiftDoc(link)}
                    >
                      <LogoContainer src={ShowIcon} alt="show Icon" />
                    </button>
                  </div>

                  {/* <div className="flex border-2 rounded  border-[#714FFF] m-2">
                    <LogoContainer src={DeleteIcon} alt=" delete logo" />
                  </div> */}

                  <div
                    className="flex  ml-12 mt-2"
                    style={{
                      border: '1px solid #714FFF',
                      borderRadius: '6px',
                      padding: '2px',
                    }}
                  >
                    {/* Prescription Section */}
                    <div className="flex flex-col  mx-2">
                      <FaPrescription
                        className="custom-icon"
                        style={{
                          fontSize: '22px',
                          borderRadius: '30%',
                          border: '1px solid #714FFF',
                          padding: '2px',
                          opacity: documentCodiId ? 0.5 : 1,
                          cursor: documentCodiId ? 'not-allowed' : 'pointer',
                          pointerEvents: documentCodiId ? 'none' : 'auto',
                        }}
                        onClick={() => {
                          if (!documentCodiId) {
                            changeDocumentType('prescription', documentId);
                          }
                        }}
                      />
                      <span>Rx</span>
                    </div>

                    {/* Vertical Line */}
                    {/* <div
                      style={{
                        height: '60px', // Adjust height to fit the tallest icon with text
                        width: '1px',
                        backgroundColor: '#714FFF',
                        margin: '0 5px',
                      }}
                    /> */}

                    {/* Invoice Section */}
                    <div className="flex flex-col items-center mx-2">
                      <FaFileInvoice
                        className="custom-icon"
                        style={{
                          fontSize: '22px',
                          borderRadius: '30%',
                          border: '1px solid #714FFF',
                          padding: '2px',
                          opacity: documentCodiId ? 0.5 : 1,
                          cursor: documentCodiId ? 'not-allowed' : 'pointer',
                          pointerEvents: documentCodiId ? 'none' : 'auto',
                        }}
                        onClick={() => {
                          if (!documentCodiId) {
                            changeDocumentType('invoice', documentId);
                          }
                        }}
                      />
                      <span>Invoice</span>
                    </div>

                    {/* Vertical Line */}
                    {/* <div
                      style={{
                        height: '60px', // Adjust height to fit the tallest icon with text
                        width: '1px',
                        backgroundColor: '#714FFF',
                        margin: '0 5px',
                      }}
                    /> */}

                    {/* Lab Report Section */}
                    <div className="flex flex-col items-center mx-2">
                      <TbTestPipe
                        className="custom-icon"
                        style={{
                          fontSize: '22px',
                          borderRadius: '30%',
                          border: '1px solid #714FFF',
                          padding: '2px',
                          opacity: documentCodiId ? 0.5 : 1,
                          cursor: documentCodiId ? 'not-allowed' : 'pointer',
                          pointerEvents: documentCodiId ? 'none' : 'auto',
                        }}
                        onClick={() => {
                          if (!documentCodiId) {
                            changeDocumentType('lab_report', documentId);
                          }
                        }}
                      />
                      <span>Lab Report</span>
                    </div>
                  </div>
                </div>
              );
            })}

          <div className="mt-2"></div>
          <div
            className="flex"
            style={{
              justifyContent: 'space-between',
              padding: '1.5rem',
              paddingBottom: 0,
            }}
          >
            <p
              className="text-[#714FFF] font-bold"
              style={{ fontSize: '18px' }}
            >
              Critical Illness
            </p>

            <div>
              <label
                for="default-toggle"
                className="inline-block relative items-center cursor-pointer ml-3"
              >
                <input
                  type="checkbox"
                  checked={isCriticalIllness}
                  id="default-toggle"
                  className="sr-only peer"
                  onChange={toggleIsCriticalIllness}
                />
                <div className="w-11 h-6 bg-purple-200  rounded-full peer dark:bg-white-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-white-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-white-600 peer-checked:bg-[#714FFF]"></div>
              </label>
            </div>
          </div>
          <div
            className="flex"
            style={{
              justifyContent: 'space-between',
              padding: '1.5rem',
              paddingBottom: 0,
            }}
          >
            <p
              className="text-[#714FFF] font-bold"
              style={{ fontSize: '18px' }}
            >
              Maternity Related
            </p>

            <div>
              <label
                for="default-toggle-2"
                className="inline-block relative items-center cursor-pointer ml-3"
              >
                <input
                  type="checkbox"
                  checked={isMaternity}
                  id="default-toggle-2"
                  className="sr-only peer"
                  onChange={() => maternityOnChange()}
                />
                <div className="w-11 h-6 bg-purple-200  rounded-full peer dark:bg-white-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-white-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-white-600 peer-checked:bg-[#714FFF]"></div>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div className="basis-2/5 ">
        <div className=" bg-white h-100 rounded  p-3  ">
          <Title className="m-2">Invoice Details</Title>
          <div>
            <label className="m-1">Merchant Name</label>
            {!showMerchantSelectOption ? (
              <InputBox
                style={{ color: '#3A3A4A', fontWeight: 'bold' }}
                name="invoiceName"
                id="invoiceName"
                value={invoiceName}
                onChange={onInvoiceItemChanged(-1, 'invoiceName')}
                placeholder="Please Enter the details"
              />
            ) : (
              ''
            )}

            {showMerchantSelectOption ? (
              <AsyncSelect
                className=""
                closeMenuOnSelect={true}
                styles={customStylesForMerchant}
                value={merchantValue}
                isClearable={true}
                cacheOptions
                placeholder="Search Merchant Name"
                loadOptions={promiseLabsPartnerOptions}
                // defaultOptions={[
                //   { label: 'Enter Center Details', code: 'OTHER', name: 'other' },
                // ]}
                components={{
                  Option: CustomOptionWithImg,
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary50: '',
                    primary25: '#EAE7FF',
                    primary: '#714FFF',
                  },
                })}
                onChange={(option) => onMerchantNameChange(option)}
              />
            ) : (
              ''
            )}
            {blacklistedWarning ? (
              <div className="flex mt-1">
                <label
                  style={{
                    borderRadius: '50%',
                    color: 'white',
                    fontSize: '9px',
                    border: '1px solid #EC6519',
                    fontWeight: 600,
                    width: '14px',
                    height: '14px',
                    backgroundColor: '#EC6519',
                    textAlign: 'center',
                    bottom: '2px',
                  }}
                >
                  i
                </label>
                <label
                  className="ml-1"
                  style={{
                    color: '#EC6519',
                    fontSize: '11px',
                    fontFamily: 'Montserrat',
                    fontWeight: 600,
                  }}
                >
                  This merchant is blacklisted. Verify from the list.
                </label>
              </div>
            ) : (
              ''
            )}
          </div>
          <div className="">
            <label className="m-1">Invoice Date</label>
            {/* <InputBox */}
            <SingleDatePicker
              style={{ color: '#3A3A4A', fontWeight: 'bold' }}
              date={invoiceDate}
              id="invoiceDate"
              block
              placeholder="Invoice Date"
              focused={invoiceDateInputFocused}
              onFocusChange={({ focused }) =>
                setInvoiceDateInputFocused(focused)
              }
              onDateChange={(date) => OnInvoiceDateChange(date)}
              openDirection="up"
              isOutsideRange={() => false}
              displayFormat="DD/MM/YYYY"
            />
          </div>
          <div className="">
            <label className="m-1">Invoice Number</label>
            <InputBox
              style={{ color: '#3A3A4A', fontWeight: 'bold' }}
              type="text"
              name="invoiceNumber"
              id="invoiceNumber"
              value={invoiceNumber}
              onChange={onInvoiceItemChanged(-1, 'invoiceNumber')}
              placeholder="Please Enter the details"
            />
          </div>
          <div className="">
            <label className="m-1">Merchant Tele Phone No.</label>
            <InputBox
              style={{ color: '#3A3A4A', fontWeight: 'bold' }}
              type="text"
              name="invoiceTeleNumber"
              id="invoiceTeleNumber"
              value={invoiceTeleNumber}
              onChange={onInvoiceItemChanged(-1, 'invoiceTeleNumber')}
              placeholder="Please Enter the details"
            />
          </div>
        </div>
      </div>

      <Modal
        isOpen={otherMerchantNameModal}
        toggle={() => otherModalDisabled()}
      >
        <ModalHeader toggle={() => otherModalDisabled()}>
          <ModalHeaderHeading>Merchant Name & Pincode</ModalHeaderHeading>
        </ModalHeader>
        <ModalBody>
          <InputBoxForPinCode
            placeholder=" Enter Merchant Name"
            type="text"
            onChange={(e) => onMerchantNameChangeNonEmpanel(e)}
          ></InputBoxForPinCode>
          <InputBoxForPinCode
            className="mt-2"
            placeholder=" Enter Pincode"
            type="text"
            onChange={(e) => fetchZipCode(e)}
          ></InputBoxForPinCode>
        </ModalBody>
        <ModalFooter>
          <button
            className="btn mt-2 mb-2"
            style={{ backgroundColor: '#714FFF', color: '#fff' }}
            disabled={!pincode || !invoiceName}
            onClick={() => addNonEmpanelCenter()}
          >
            Save
          </button>
        </ModalFooter>
      </Modal>
    </div>
  );
}
