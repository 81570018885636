import React, { useEffect, useState, useCallback } from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import HistoryContainer from './HistoryContainer';
import ReimbursementClaimsRequestsTable from './ReimbursementClaimsRequestsTable';
import moment from 'moment';
import { store } from '../../store/';
import ReactTooltip from 'react-tooltip';
import { useDispatch, useSelector } from 'react-redux';
import { setReimbursement } from '../../actions';
import styled from 'styled-components';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { debounce } from '../../utils/debounce';
import SponsorFilter from '../../components/common/SponsorFilter';
import { UpArrow, ArrowRightLeft } from '../../components/common/svgFiles';
import ReClaimIcon from '../../assets/images/re-claim.png';
import ViewClaim from '../../assets/images/EyeIcon.png';
import RegeneratePng from '../../assets/images/regenerate.png';
import ColumnFilter from '../../components/common/ColumnFilter';
import {
  useFetchReimbursementData,
  useClaimRequest,
} from '../../queries/reimbursementQuery';
import {
  downloadReimbursementCsvFile,
  downloadCsvBroker,
  regenerateDebitNoteApi,
  fetchComms,
} from '../../services/reimbursementClaims';
import '../../styling/table.css';
import '../../styling/react-table.css';
import { connect } from 'react-redux';
import DoctorsFilter from '../AdminReimbursement/DoctorsFilter';
import VendorFilter from '../../components/common/VendorFilter';
import { FiMessageSquare } from 'react-icons/fi';
import CommsModal from './CommsModal';
import DataImg from '../../../src/assets/images/dataNotFound.gif';

const LogoContainer = styled.img`
  max-width: 2rem;
`;
const capitalizeFirst = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

const title = {
  'font-size': '18px',
  color: '#714FFF',
  'font-family': 'Montserrat',
  'font-weight': 'bold',
};

const Wrapper = styled.div`
  display: block;
  background: grey;
  ${(props) => {
    if (props.toggle) {
      return `
        position: absolute;
        left: 1px;
        right: auto;
        top: 2px;
        background: blue;
      `;
    } else {
      return `
        position: absolute;
        left: auto;
        top: 2px;
        right: 30px;
        background: yellow;
      `;
    }
  }}
`;

const styleobj = {
  'font-size': '15px',
  overflow: 'hidden',
  'background-color': '#FFF',
  'font-weight': '500',
  'font-family': 'Montserrat',
  position: 'relative',
};
const vipStyleObj = {
  'border-radius': '22px',
  border: '1px solid #3A2CA0',
  background: '#FFF',
  width: '53px',
  height: '24px',
  color: '#714FFF',
};

function ReimbursementClaims({ userName }) {
  const dispatch = useDispatch();
  const state = store.getState();
  const location = useLocation();
  const navigate = useNavigate();
  const opsUserName = state.app.opsUser.name;
  const [rId, setReimbursementId] = useState('');
  let [pages, setCount] = useState(-1);
  const { brokerId, showReimbursementAdmin } = useSelector(
    (state) => state.app.opsUser
  );

  const [sponsorName, setSponsorName] = useState('');
  const [pageSize, setPageSize] = useState(20);
  const [page, setPage] = useState(0);
  const [sortBy, setSortBy] = useState('');
  const [sortOrder, setSortOrder] = useState('');
  const [myCasesFilter, setAllCasesFilter] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedDate, setSelectedDate] = useState({ sd: null, ed: null });
  const [focusedInput, setFocusedInput] = useState(null);
  const [claimBy, setClaimBy] = useState('');
  const [searchString, setSearchString] = useState('');
  const toastTheme = 'dark';
  const [status, setStatus] = useState('documents-uploaded');
  const [deficientTab, setDeficientTab] = useState('');
  const [claimStatus, setClaimStatus] = useState('');
  const [reimbursementType, setReimbursementType] = useState();
  const toggleChecked = () => setAllCasesFilter((value) => !value);
  const [isBrokerFileDownload, setIsBrokerFileDownload] = useState(false);
  const searchKeyword = useSelector((state) => state.app.searchString);
  const [vendorName, setVendorName] = useState('');
  const [loading, setIsLoading] = useState(false);
  const [commsData, setCommsData] = useState([]);
  const [isCommsModal, setIsCommModal] = useState(false);
  const [requestId, setRequestId] = useState(null);

  useEffect(() => {
    if (myCasesFilter) {
      setClaimBy(opsUserName);
    }
    if (!myCasesFilter) {
      setClaimBy('');
    }
  }, [myCasesFilter]);

  const {
    isLoading,
    error,
    data,
    isFetching,
    refetch: refreshReimburseData,
  } = useFetchReimbursementData(
    page,
    pageSize,
    sortBy,
    sortOrder,
    myCasesFilter,
    claimBy,
    selectedDate.sd && moment(selectedDate.sd).unix(),
    selectedDate.ed && moment(selectedDate.ed).unix(),
    rId,
    sponsorName,
    searchString,
    status,
    claimStatus,
    reimbursementType,
    deficientTab,
    vendorName
  );
  pages = data && Math.ceil(data.totalRecords / pageSize);

  const downloadCsv = async () => {
    const params = {
      sponsorName,
      myCasesFilter,
      size: 50000,
      page: 1,
      claimBy,
      status,
      claimStatus,
      q: searchString,
      rId,
      startDate: selectedDate.sd && moment(selectedDate.sd).unix(),
      endDate: selectedDate.ed && moment(selectedDate.ed).unix(),
    };
    await downloadReimbursementCsvFile(params);
  };

  useEffect(() => {
    if (searchKeyword) {
      setSearchString(searchKeyword);
      setStatus('');
      refreshReimburseData();
    } else {
      setStatus('documents-uploaded');
      setSearchString('');
      refreshReimburseData();
    }
  }, [searchKeyword]);

  useEffect(() => {
    if (data && data.results) {
      console.log('in effect ');
      dispatch(setReimbursement(data.results));
    }
  }, [data && data.results]);

  const OnSuccess = () => {
    toast.success('Request is claimed successfully', {
      icon: '✅',
      theme: toastTheme,
    });
    refreshReimburseData();
  };
  const OnError = (error) => {
    toast.error(
      error.errorMessage || 'Something went wrong. Please try again later',
      { icon: '❌', theme: toastTheme }
    );
  };
  const { mutate: claimRequest } = useClaimRequest(OnSuccess, OnError);

  const prepareSorting = (state) => {
    const { sorted } = state;
    const reimbursementIdSort = Array.isArray(sorted)
      ? sorted.find((ele) => ele.id === 'reimbursementId')
        ? 'reimbursementId'
        : undefined
      : undefined;
    const reimbursementIdSortOrder = Array.isArray(sorted)
      ? sorted.find((ele) => ele.id === 'reimbursementId' || {}).desc
        ? 'desc'
        : 'asc'
      : undefined;
    setSortOrder(reimbursementIdSortOrder);
    setSortBy(reimbursementIdSort);
  };

  // const onDatesChanged = ({ startDate, endDate }) => {
  //     setStartDate(startDate);
  //     setEndDate(endDate);
  //     if (startDate && endDate) {
  //         setSelectedDate({ sd: startDate, ed: endDate })
  //     }
  // }

  const onStartDateChange = (e) => {
    setStartDate(e.target.value);
  };
  const onEndDateChange = (e) => {
    setEndDate(e.target.value);
  };
  useEffect(() => {
    if (startDate && endDate) {
      setSelectedDate({ sd: startDate, ed: endDate });
    }
  }, [endDate]);

  const filterData = (state) => {
    console.log('filter---', state.filtered);
    const { filtered } = state;
    const idFilter =
      filtered && filtered.find((f) => f.id === 'reimbursementId');
    const claimFilter = filtered && filtered.find((f) => f.id === 'claimBy');
    const sponsorFilter =
      filtered && filtered.find((f) => f.id === 'sponsorName');
    const statusFilter =
      filtered && filtered.find((f) => f.id === 'requestStatus');
    const insurerFilter =
      filtered && filtered.find((f) => f.id === 'claimStatus');
    const vendorFilter =
      filtered && filtered.find((f) => f.id === 'vendorName');
    if (idFilter) {
      setReimbursementId(idFilter.value);
      setStatus('all');
    }

    if (!idFilter) {
      setReimbursementId('');
      setStatus('documents-uploaded');
    }
    if (claimFilter && claimFilter.value.value !== 'unassigned') {
      setClaimBy(claimFilter?.value.label);
    }
    if (claimFilter && claimFilter.value.value === 'unassigned') {
      setClaimBy('');
    }
    if (!claimFilter) {
      setClaimBy('');
    }
    if (sponsorFilter) {
      setSponsorName(sponsorFilter.value.value);
    }
    if (!sponsorFilter) {
      setSponsorName('');
    }
    if (vendorFilter) {
      setVendorName(vendorFilter.value.value);
    }
    if (!vendorFilter) {
      setVendorName('');
    }
    if (statusFilter && statusFilter?.value !== 'deficiency-submitted') {
      setStatus(statusFilter?.value);
      setDeficientTab('');
    }
    if (statusFilter && statusFilter?.value === 'deficiency-submitted') {
      setStatus('documents-uploaded');
      setDeficientTab('true');
    }
    if (insurerFilter) {
      setClaimStatus(insurerFilter.value);
    }
  };
  const claimRequestProcess = (reimbursementId) => () => {
    claimRequest(reimbursementId);
  };

  const regenerateDebitNote = async (reimbursementId) => {
    console.log(reimbursementId, '[reimbursementId][clicked]');
    const body = { reimbursementId };

    try {
      setIsLoading(true);

      const response = await regenerateDebitNoteApi(body);
      console.log(response, '[response]');

      setIsLoading(false);

      if (response.data.result) {
        toast.success(`${response.data.result.message}`, {
          icon: '✅',
          theme: toastTheme,
        });
        refreshReimburseData();
      } else {
        toast.error(`${response.data.errorMessage}`, {
          icon: '❌',
          theme: toastTheme,
        });
      }
    } catch (error) {
      setIsLoading(false);
      console.error('Error during API call', error);
      toast.error('Failed to regenerate debit note!', {
        icon: '❌',
        theme: toastTheme,
      });
    }
  };

  const fetchCommsInfo = async (requestId) => {
    await fetchComms(requestId)
      .then((result) => {
        setRequestId(requestId);
        setCommsData(result);
        setIsCommModal(true);
      })
      .catch((err) => {
        console.log({ err });
      });
  };

  const reimbursementColumn = () => [
    {
      Header: () => (
        <div
          className="flex flex-row"
          style={{ backgroundColor: '', margin: '0px' }}
        >
          Reimbursement ID
          <UpArrow />
        </div>
      ),
      accessor: 'reimbursementId',
      filterable: true,
      Filter: ColumnFilter,
      width: 170,
      style: { textAlign: 'center', fontWeight: '600' },
      Cell: (row) => {
        return (
          <div className="flex flex-row">
            {row.original.isVipUser ? (
              <>
                <h2
                  className="mr-2"
                  style={{ textAlign: 'center', fontWeight: '600' }}
                >
                  {row.original.reimbursementId}
                </h2>
                <label style={vipStyleObj}>VIP</label>
              </>
            ) : (
              <h2
                className="ml-4"
                style={{ textAlign: 'center', fontWeight: '600' }}
              >
                {row.original.reimbursementId}
              </h2>
            )}
          </div>
        );
      },
    },
    {
      Header: () => (
        <div className="flex flex-row">
          {' '}
          Claimed By <UpArrow />
        </div>
      ),
      accessor: 'claimBy',
      filterable: true,
      width: 170,
      Filter: ({ filter, onChange }) => (
        <DoctorsFilter onChangeValue={onChange} value={filter ? filter : ''} />
      ),
      style: { textAlign: 'center', fontWeight: '600' },
    },
    {
      Header: 'Sponsor Name ',
      accessor: 'sponsorName',
      width: 170,
      filterable: brokerId ? false : true,
      style: { textAlign: 'center', fontWeight: '600' },
      Filter: ({ filter, onChange }) => (
        <SponsorFilter onChangeValue={onChange} value={filter ? filter : ''} />
      ),
    },
    {
      Header: 'User Name',
      accessor: 'userName',
      width: 170,
      style: { textAlign: 'center', fontWeight: '600' },
    },
    {
      Header: () => (
        <div className="flex flex-row">
          Patient Name
          <UpArrow />
        </div>
      ),
      width: 150,
      style: { textAlign: 'center', fontWeight: '600' },

      Cell: (row) => {
        return <span>{row.original.relative || row.original.userName}</span>;
      },
    },
    {
      Header: () => (
        <div className="flex flex-row">
          Status
          <UpArrow />
        </div>
      ),
      accessor: 'requestStatus',
      width: 200,
      filterable: true,
      style: { textAlign: 'center', fontWeight: '600' },
      Filter: ({ filter, onChange }) => {
        return (
          <select
            onChange={(event) => onChange(event.target.value)}
            // style={{ width: '100%' }}
            value={filter ? filter.value : 'documents-uploaded'}
          >
            <option value="all">Show All</option>
            <option value="documents-uploaded">Documents Uploaded</option>
            <option value="approved">Approved</option>
            <option value="rejected">Rejected</option>
            <option value="documents-deficient">Documents Deficient</option>
            <option value="deficiency-submitted">Deficiency Submitted</option>
            <option value="documents-verified">Documents Verified</option>
            <option value="clarification-needed">Clarification Needed</option>
            <option value="clarification-resolved">
              Clarification Resolved
            </option>
            <option value="on-hold">On Hold</option>
          </select>
        );
      },
      Cell: (row) => {
        if (row.original.deletionDate) {
          return <h5 className="text-[#D53815] font-bold">Deleted</h5>;
        }
        if (!row.original.requestStatus) {
          return 'N.A';
        }
        switch (row.original.requestStatus.toUpperCase()) {
          case 'DOCUMENTS-UPLOADED':
            if (row.original.documentDefUpdatedOn) {
              return (
                <h5 className="text-[#714FFF] font-bold">
                  {capitalizeFirst('Deficiency-submitted')}
                  <br></br>
                  {row.original.subStatus ? (
                    <span className="badge badge-secondary">
                      {row.original.subStatus.toUpperCase()}
                    </span>
                  ) : null}
                </h5>
              );
            } else {
              return (
                <h5 className="text-[#714FFF] font-bold">
                  {capitalizeFirst(row.original.requestStatus)}
                  <br></br>
                  {row.original.subStatus ? (
                    <span className="badge badge-secondary">
                      {row.original.subStatus.toUpperCase()}
                    </span>
                  ) : null}
                </h5>
              );
            }
          case 'DOCUMENTS-VERIFIED':
            return (
              <h5 className="text-[#3899D3] font-bold">
                {capitalizeFirst(row.original.requestStatus)}
                <br></br>
                {row.original.subStatus ? (
                  <span className="badge badge-secondary">
                    {row.original.subStatus.toUpperCase()}
                  </span>
                ) : null}
              </h5>
            );
          case 'APPROVED':
            return (
              <h5 className="text-[#72BE11] font-bold">
                {capitalizeFirst(row.original.requestStatus)}
                <br></br>
                {row.original.subStatus ? (
                  <span className="badge badge-secondary">
                    {row.original.subStatus.toUpperCase()}
                  </span>
                ) : null}
              </h5>
            );
          case 'REJECTED':
            return (
              <h5 className="text-[#D53815] font-bold">
                {capitalizeFirst(row.original.requestStatus)}
                <br></br>
                {row.original.subStatus ? (
                  <span className="badge badge-secondary">
                    {row.original.subStatus.toUpperCase()}
                  </span>
                ) : null}
              </h5>
            );
          default:
            return (
              <h5 className="text-[#008B8B] font-bold">
                {capitalizeFirst(row.original.requestStatus)}
                <br></br>
                {row.original.subStatus ? (
                  <span className="badge badge-secondary">
                    {row.original.subStatus.toUpperCase()}
                  </span>
                ) : null}
              </h5>
            );
        }
      },
    },
    {
      Header: () => (
        <div className="flex flex-row">
          Reimbursement Type
          <UpArrow />
        </div>
      ),
      width: 150,
      accessor: 'reimbursementType',
      style: { textAlign: 'center', fontWeight: '600' },
    },
    {
      Header: () => (
        <div className="flex flex-row">
          Insurer Status
          <UpArrow />
        </div>
      ),
      width: 130,
      accessor: 'claimStatus',
      filterable: true,
      style: { textAlign: 'center', fontWeight: '600' },
      Filter: ({ filter, onChange }) => {
        return (
          <select
            onChange={(event) => onChange(event.target.value)}
            // style={{ width: '100%' }}
            value={filter ? filter.value : 'all'}
          >
            <option value="all">Show All</option>
            <option value="settled">Settled</option>
            <option value="pending">Pending</option>
          </select>
        );
      },
      Cell: (row) => {
        // eslint-disable-next-line default-case
        switch (row.original.claimStatus.toUpperCase()) {
          case 'PENDING':
            return (
              <div
                className=" flex flex-column mb-2"
                style={{
                  border: '2px solid #EC9719',
                  borderRadius: '5px',
                  padding: '0px',
                }}
              >
                <span className="text-center"> Insurer</span>
                <button
                  type="button"
                  style={{
                    backgroundColor: '#EC9719',
                    color: '#fff',
                    padding: '5px',
                  }}
                >
                  {capitalizeFirst(row.original.claimStatus)}
                </button>
              </div>
            );

          case 'REJECTED':
          case 'FAILURE':
          case 'REVERSED':
            return (
              <div
                className="flex flex-column border-2 mb-2"
                style={{
                  border: '2px solid #D53815',
                  borderRadius: '5px',
                  padding: '0px',
                }}
              >
                <span className="text-center"> Insurer</span>
                <button
                  type="button"
                  className="py-2 px-3 text-xs font-medium"
                  style={{
                    backgroundColor: '#D53815',
                    color: '#fff',
                    padding: '5px',
                  }}
                >
                  {capitalizeFirst(row.original.claimStatus)}
                </button>
              </div>
            );
          case 'APPROVED':
            return (
              <div
                className="flex flex-column mb-2"
                style={{
                  border: '2px solid #72BE11',
                  borderRadius: '5px',
                  padding: '0px',
                }}
              >
                <span className="text-center"> Insurer</span>
                <button
                  type="button"
                  className="py-2 px-3 text-xs font-medium"
                  style={{
                    backgroundColor: '#72BE11',
                    color: '#fff',
                    padding: '5px',
                  }}
                >
                  {capitalizeFirst(row.original.claimStatus)}
                </button>
              </div>
            );

          case 'SETTLED':
            return (
              <div
                className="flex flex-column mb-2"
                style={{
                  border: '2px solid #3899D3',
                  borderRadius: '5px',
                  padding: '0px',
                }}
              >
                <span className="text-center"> Insurer</span>
                <button
                  type="button"
                  className="py-2 px-3 text-xs font-medium "
                  style={{
                    backgroundColor: '#3899D3',
                    color: '#fff',
                    padding: '5px',
                  }}
                >
                  {capitalizeFirst(row.original.claimStatus)}
                </button>
              </div>
            );
        }
      },
    },
    {
      Header: () => (
        <div className="flex flex-row">
          Insurer Name
          <UpArrow />
        </div>
      ),
      filterable: brokerId ? false : true,
      width: 170,
      accessor: 'vendorName',
      style: { textAlign: 'center', fontWeight: '600' },
      Filter: ({ filter, onChange }) => (
        <VendorFilter onChangeValue={onChange} value={filter ? filter : ''} />
      ),
    },
    {
      Header: 'Request Date',
      accessor: 'requestDate',
      width: 170,
      style: {
        fontWeight: '600',
      },
      Cell: (row) => (
        <span>
          {row.original.requestDate
            ? moment(row.original.requestDate).format('DD/MM/YYYY HH:mm')
            : null}
        </span>
      ),
    },
    {
      Header: 'Claim Amount',
      accessor: 'reimbursementAmount',
      width: 130,
      style: { textAlign: 'center', fontWeight: '600' },
      Cell: (row) => (
        <span>{`₹ ${row.original.reimbursementAmount || 'NA'}`}</span>
      ),
    },
    {
      Header: 'Approved Amount',
      accessor: 'approvedAmount',
      width: 160,
      style: { textAlign: 'center', fontWeight: '600' },
      Cell: (row) => <span>{`₹ ${row.original.approvedAmount || 'NA'}`}</span>,
    },
    {
      Header: 'Actions',
      width: 110,
      numeric: false,
      disablePadding: false,
      style: {
        whiteSpace: 'unset',
        position: 'absolute',
        right: 0,
        background: 'white',
        height: '1110px',
        boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.25)',
        fontFamily: 'Montserrat',
      },
      headerStyle: {
        overflow: 'visible',
        position: 'absolute',
        right: 0,
        background: '#FFFFFF',
        textAlign: 'center',
      },
      Cell: (row) => {
        return (
          <div className="flex flex-row cursor-pointer ml-3 flex-wrap">
            <ReactTooltip id="claimView" place="top" effect="float" type="info">
              View Claim
            </ReactTooltip>
            <Link
              to={`/reimburse-claim/${row.original.reimbursementId}`}
              target="_blank"
            >
              <LogoContainer
                data-tip
                data-for="claimView"
                className="mr-2"
                src={ViewClaim}
                alt="view claim"
              />
            </Link>

            {brokerId && brokerId ? (
              ''
            ) : (
              <>
                <ReactTooltip
                  id="reClaimReq"
                  place="top"
                  effect="float"
                  type="success"
                >
                  Re-Claim Request
                </ReactTooltip>

                <LogoContainer
                  data-tip
                  data-for="reClaimReq"
                  className="mr-1"
                  src={ReClaimIcon}
                  alt="Reclaim Logo"
                  onClick={claimRequestProcess(row.original.reimbursementId)}
                />
              </>
            )}
            <div className="flex flex-row mt-2 mb-10">
              {loading ? (
                <div className="loader">Loading...</div>
              ) : (
                <>
                  <ReactTooltip
                    id="regenerateDebitNote"
                    place="top"
                    effect="float"
                    type="info"
                  >
                    Regenerate Debit Note
                  </ReactTooltip>
                  <LogoContainer
                    data-tip
                    data-for="regenerateDebitNote"
                    className="mr-2"
                    src={RegeneratePng}
                    alt="regeneratePng"
                    onClick={() =>
                      regenerateDebitNote(row.original.reimbursementId)
                    }
                  />
                  <ReactTooltip
                    id="communicationLog"
                    place="top"
                    effect="float"
                    type="info"
                  >
                    Communication Logs
                  </ReactTooltip>
                  <FiMessageSquare
                    style={{
                      color: '#0D9CF0',
                      fontSize: '2rem',
                      outline: 'none',
                    }}
                    data-tip
                    data-for="communicationLog"
                    className="mr-2"
                    onClick={() => fetchCommsInfo(row.original.reimbursementId)}
                  />
                </>
              )}
            </div>
          </div>
        );
      },
    },
  ];
  const getTrProps = (state, rowInfo, instance) => {
    if (rowInfo && rowInfo.row) {
      return {
        style: {
          background: rowInfo.original.isSuspicious ? '#FFF9F9' : '#ffff',
          border: rowInfo.original.isSuspicious ? '1px solid #FF6262' : '',
        },
      };
    }

    return {};
  };

  const pendingCase =
    data &&
    data.reimbursementCasesCount.find(
      (element) => element.claim_status === 'pending'
    );
  const ApprovedCases =
    data &&
    data.reimbursementCasesCount.find(
      (element) => element.claim_status === 'approved'
    );
  const todayCases = data && data.dailycasesCount;

  function downloadArrayOfObjectsAsCSV(data, filename) {
    const headers = Object.keys(data[0]).join(',') + '\n';
    const rows = data.map((row) => Object.values(row).join(',')).join('\n');
    const csvData = headers + rows;
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });

    if (navigator.msSaveBlob) {
      // For IE 10+
      navigator.msSaveBlob(blob, filename);
    } else {
      const link = document.createElement('a');
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', filename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      }
    }
  }

  const DownloadCsvForBroker = () => {
    setIsBrokerFileDownload(true);
    const params = {
      page,
      size: 50000,
      claimBy,
      startDate,
      endDate,
      sponsorName,
      status,
      claimStatus,
      reimbursementType: reimbursementType ? reimbursementType : '',
      deficientTab: deficientTab ? deficientTab : '',
      sortBy,
      sortOrder,
    };
    downloadCsvBroker(params).then((result) => {
      const rawData = result?.results;
      setIsBrokerFileDownload(false);
      return downloadArrayOfObjectsAsCSV(
        rawData && rawData,
        `Reimbursement_Report_${moment().format('YYYY-MM-DD')}`
      );
    });
  };

  if (error) {
    const errorMsg = error?.response?.data?.errorMessage;
    toast.error(errorMsg || 'Something went wrong. Please try again later', {
      icon: '❌',
      theme: toastTheme,
    });
  }

  const onModalClose = () => {
    setIsCommModal(false);
  };

  const CustomNoDataComponent = (data) => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%', // Fill the table body
          width: '100%', // Ensure full width
          color: '#888',
          fontSize: '16px',
          position: 'absolute', // Ensure it overlaps table content
          pointerEvents: 'none',
        }}
      >
        <img
          src={DataImg}
          alt="No Data"
          style={{ width: '250px', height: '250px', marginBottom: '0px' }}
        />
        <p style={{ fontWeight: 600 }}>No data found</p>
      </div>
    );
  };
  return (
    <>
      <div
        className="container-fluid  overflow-auto ... w-full ... px-0 "
        style={{ backgroundColor: '#F5F3F8' }}
      >
        <div
          className="flex flex-row"
          style={{ justifyContent: 'space-between' }}
        >
          <div className="pl-3 mt-4">
            <p style={title}>Reimbursement Claims</p>
          </div>
          {brokerId && brokerId ? (
            ''
          ) : (
            <div className="text-sm mt-2">
              {showReimbursementAdmin ? (
                <div
                  className="  flex border-2 border-[#714FFF] rounded-md pl-3 mt-3"
                  style={{ backgroundColor: '#FFFFFF' }}
                >
                  <p
                    className="font-bold text-[#714FFF] dark:text-[#714FFF]"
                    style={{ marginTop: '10px' }}
                  >
                    All Cases
                  </p>

                  <label
                    for="default-toggle"
                    className="inline-flex relative items-center cursor-pointer mt-2 ml-3"
                  >
                    <input
                      type="checkbox"
                      value={myCasesFilter}
                      id="default-toggle"
                      className="sr-only peer"
                      onChange={toggleChecked}
                    />

                    <div className="w-11 h-6 bg-purple-200  rounded-full peer dark:bg-white-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-white-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-white-600 peer-checked:bg-[#714FFF]"></div>
                    <span className="ml-3 mr-3 font-bold text-[#714FFF] dark:text-[#714FFF] ">
                      My Cases
                    </span>
                  </label>
                </div>
              ) : (
                ''
              )}
            </div>
          )}
          {brokerId && brokerId ? (
            ''
          ) : (
            <div className="rounded-md mt-3">
              <table className="" style={{ width: '350px' }}>
                <thead className="">
                  <tr>
                    <th
                      style={{ backgroundColor: '#EBE7FF', color: '#1E1E1E' }}
                    >
                      Total Cases
                    </th>
                    {/* <th>Completed Cases</th>
                                    <th>Pending Cases</th> */}
                    <th
                      style={{ backgroundColor: '#EBE7FF', color: '#1E1E1E' }}
                    >
                      Today Completed Cases
                    </th>
                    <th
                      style={{ backgroundColor: '#EBE7FF', color: '#1E1E1E' }}
                    >
                      Pending Cases
                    </th>
                  </tr>
                </thead>
                <tbody style={{ color: '#714FFF' }}>
                  <tr>
                    <td>{data && data.totalRecords ? data.totalRecords : 0}</td>
                    {/* <td>{ApprovedCases && ApprovedCases.records ? ApprovedCases.records : 0}</td> */}
                    <td>
                      {todayCases ? todayCases.reimbursementCasesCount : 0}
                    </td>
                    <td>
                      {pendingCase && pendingCase.records
                        ? pendingCase.records
                        : 0}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}
          <>
            {brokerId && brokerId ? (
              <div className=" mt-4 mr-2">
                <button
                  className="ml-4 btn float-right"
                  style={{ backgroundColor: '#714fff', color: '#fff' }}
                  disabled={
                    (data && data?.results?.length === 0) ||
                    isBrokerFileDownload
                  }
                  // onClick={() =>
                  //   downloadArrayOfObjectsAsCSV(
                  //     data && data?.results,
                  //     `Reimbursement_Report_${moment().format('YYYY-MM-DD')}`
                  //   )
                  // }
                  onClick={() => DownloadCsvForBroker()}
                >
                  {isBrokerFileDownload ? 'Loading..' : 'Download CSV'}
                </button>
              </div>
            ) : (
              <>
                <div className=" flex pt-4">
                  <div
                    className="border-2 p-1"
                    style={{
                      border: '2px solid #FFFFFF',
                      borderRadius: '10px',
                      backgroundColor: '#FFFFFF',
                      paddingBottom: '28px',
                      height: '3rem',
                    }}
                  >
                    <label
                      className="block p-0"
                      style={{
                        color: '#714FFF',
                        fontSize: '12px',
                        fontFamily: 'Montserrat',
                        fontStyle: 'bold',
                        marginBottom: '0rem',
                        fontWeight: 'bold',
                      }}
                    >
                      Start From
                    </label>

                    <input
                      className="p-0"
                      type="date"
                      style={{ color: '#3A3A49' }}
                      onChange={(e) => onStartDateChange(e)}
                    ></input>
                  </div>
                  <div className=" mt-3">
                    <ArrowRightLeft />
                  </div>

                  <div
                    className="border-2 p-1"
                    style={{
                      border: '2px solid #FFFFFF',
                      borderRadius: '10px',
                      backgroundColor: '#FFFFFF',
                      height: '3rem',
                    }}
                  >
                    <label
                      className="block p-0"
                      style={{
                        color: '#714FFF',
                        fontSize: '12px',
                        fontFamily: 'Montserrat',
                        fontStyle: 'bold',
                        marginBottom: '0rem',
                        fontWeight: 'bold',
                      }}
                    >
                      End on
                    </label>

                    <input
                      className="p-0"
                      type="date"
                      style={{ color: '#3A3A49' }}
                      onChange={onEndDateChange}
                    ></input>
                  </div>
                </div>
                <div className=" mt-4 mr-2">
                  <button
                    className="ml-4 btn float-right"
                    style={{ backgroundColor: '#714fff', color: '#fff' }}
                    onClick={downloadCsv}
                  >
                    Download CSV
                  </button>
                </div>
              </>
            )}
          </>
        </div>

        <div className="pt-12 " style={{ overflow: 'scroll', height: '100vh' }}>
          <ReactTable
            keyField="reimbursementId"
            //className="-striped -highlight"
            data={data && data.results}
            pages={pages}
            manual
            defaultSorted={[
              {
                id: 'reimbursementId',
                desc: true,
              },
            ]}
            columns={reimbursementColumn()}
            showPaginationTop={false}
            //filtered={filtered}
            //onFilteredChange={onFilteredChange}
            useFlexLayout={true}
            showPageJump={false}
            loading={isLoading || isFetching}
            style={styleobj}
            pageSizeOptions={[5, 10, 20, 25, 50, 100, 150]}
            NoDataComponent={() => CustomNoDataComponent(data && data.results)}
            onFetchData={(state, instance) => {
              prepareSorting(state);
              filterData(state);
              setPage(state.page + 1);
              setPageSize(state.pageSize);
            }}
            getTrProps={getTrProps}
            SubComponent={({ row }) => {
              return (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: '34px',
                  }}
                >
                  <HistoryContainer requestId={row.reimbursementId} />
                  <ReimbursementClaimsRequestsTable
                    details={[row._original]}
                    requestId={row.reimbursementId}
                  />
                </div>
              );
            }}
          />
          <CommsModal
            isOpen={isCommsModal}
            commsData={commsData}
            onClose={onModalClose}
            requestId={requestId}
          ></CommsModal>
        </div>
        {/* <ToastContainer /> */}
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  const userName = state.app.opsUser?.name;
  const searchString = state.app.searchString;
  const brokerId = state.app.opsUser?.brokerId;
  return { userName, searchString, brokerId };
};
export default connect(mapStateToProps)(ReimbursementClaims);
