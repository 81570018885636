import axios from 'axios';
import config from '../config';
import { store } from '../store/';

export async function getReimbursementDoctorList() {
  const headers = {};
  const state = store.getState();
  if (state.app.authToken) {
    headers.authorization = state.app.authToken;
  }
  return await axios
    .get(`${config.baseUrl}/claims-support/get-reimbursement-doctor-list`, {
      headers,
    })
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody.result;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      throw err;
    });
}
export async function transferReimbursementCase(body) {
  const headers = {};
  const state = store.getState();
  if (state.app.authToken) {
    headers.authorization = state.app.authToken;
  }
  return await axios
    .post(
      `${config.baseUrl}/claims-support/v2/reimbursements/reassign-reimbursements-doctor`,
      body,
      { headers }
    )
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody.responseMessage;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      throw err;
    });
}
export async function getReimbursementSponsorDoctorList() {
  const headers = {};
  const state = store.getState();
  if (state.app.authToken) {
    headers.authorization = state.app.authToken;
  }
  return await axios
    .get(`${config.baseUrl}/claims-support/reimbursements/doctor-sponsors`, {
      headers,
    })
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody.data;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      throw err;
    });
}

export async function getSponsorList() {
  const headers = {};
  const state = store.getState();
  if (state.app.authToken) {
    headers.authorization = state.app.authToken;
  }
  return await axios
    .get(`${config.baseUrl}/claims-support/get-sponsor-list`, { headers })
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody.result;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      throw err;
    });
}

export async function addDoctorSponsors(body) {
  const headers = {};
  const state = store.getState();
  if (state.app.authToken) {
    headers.authorization = state.app.authToken;
  }
  return await axios
    .post(
      `${config.baseUrl}/claims-support/reimbursements/doctor-sponsors `,
      body,
      { headers }
    )
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody.responseMessage;
      } else {
        const err = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      throw err;
    });
}

export async function removeDoctorSponsors(doctorId) {
  const headers = {};
  const state = store.getState();
  if (state.app.authToken) {
    headers.authorization = state.app.authToken;
  }
  return await axios
    .delete(
      `${config.baseUrl}/claims-support/reimbursements/doctor-sponsors?doctorId=${doctorId}`,
      { headers }
    )
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody.responseMessage;
      } else {
        const err = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      throw err;
    });
}

export async function updateDoctorSponsors(body) {
  const headers = {};
  const state = store.getState();
  if (state.app.authToken) {
    headers.authorization = state.app.authToken;
  }
  return await axios
    .put(
      `${config.baseUrl}/claims-support/reimbursements/doctor-sponsors`,
      body,
      { headers }
    )
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody.responseMessage;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      throw err;
    });
}
export async function addClarificationSolution(body) {
  const headers = {};
  const state = store.getState();
  if (state.app.authToken) {
    headers.authorization = state.app.authToken;
  }
  return await axios
    .put(
      `${config.baseUrl}/claims-support/reimbursement-clarification-solution`,
      body,
      { headers }
    )
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody.responseMessage;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      throw err;
    });
}
export async function getClaimsRemarkList() {
  const headers = {};
  const state = store.getState();
  if (state.app.authToken) {
    headers.authorization = state.app.authToken;
  }
  return await axios
    .get(
      `${config.baseUrl}/claims-support/get-deficiency-and-rejection-remarks`,
      {
        headers,
      }
    )
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody.result;
      } else {
        const err = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      throw err;
    });
}

export const downloadAdminReimbursementCsvFile = (params) => {
  const urlQuery = new URLSearchParams(params).toString();
  const url = `${config.baseUrl}/claims-support/download-reimbursements-csv?${urlQuery}`;
  const headers = {};
  const state = store.getState();
  if (state.app.authToken) {
    headers.authorization = state.app.authToken;
  }
  window.open(`${url}&auth=${headers.authorization.split(' ')[1]}`);
  return Promise.resolve();
};

export const downloadReimbursementDoctorsCountFile = (params) => {
  const headers = {};
  const state = store.getState();
  if (state.app.authToken) {
    headers.authorization = state.app.authToken;
  }
  const urlQuery = new URLSearchParams(params).toString();

  const url = `${config.baseUrl}/claims-support/download-mis-report-csv?${urlQuery}`;
  window.open(`${url}&auth=${headers.authorization.split(' ')[1]}`);
  return Promise.resolve();
};

export async function CreateDoctorAccount(body) {
  const headers = {};
  const state = store.getState();
  if (state.app.authToken) {
    headers.authorization = state.app.authToken;
  }
  return await axios
    .post(`${config.baseUrl}/claims-support/create-credentials`, body, {
      headers,
    })
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      throw err;
    });
}

export async function getSuspiciousClaimUsers() {
  const headers = {};
  const state = store.getState();
  if (state.app.authToken) {
    headers.authorization = state.app.authToken;
  }
  return await axios
    .get(`${config.baseUrl}/claims-support/fetch/all-suspicious-claim-users`, {
      headers,
    })
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.success) {
        return responseBody.result;
      } else {
        const err = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      throw err;
    });
}

export async function fetchAllSuspiciousUserClaim(userId) {
  const headers = {};
  const state = store.getState();
  if (state.app.authToken) {
    headers.authorization = state.app.authToken;
  }
  return await axios
    .get(`${config.baseUrl}/claims-support/suspicious-claim/${userId}`, {
      headers,
    })
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.success) {
        return responseBody.result;
      } else {
        const err = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      throw err;
    });
}
export async function deleteSuspiciousUser(userId) {
  const headers = {};
  const state = store.getState();
  if (state.app.authToken) {
    headers.authorization = state.app.authToken;
  }
  return await axios
    .delete(`${config.baseUrl}/claims-support/suspicious-claim/${userId}`, {
      headers,
    })
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.success) {
        return responseBody.result;
      } else {
        const err = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      throw err;
    });
}

export async function retriggerRequest(body) {
  const headers = {};
  const state = store.getState();
  if (state.app.authToken) {
    headers.authorization = state.app.authToken;
  }
  return await axios
    .post(`${config.baseUrl}/new-ops/retrigger-comms`, body, {
      headers,
    })
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      throw err;
    });
}
