import React, { useState, useEffect } from 'react';
import { Table, Button, Tooltip, Row, Col, Modal } from 'antd';
import DOMPurify from 'dompurify';
import '../../../src/App.css';
import { SearchIcon } from '../../components/common/svgFiles';
import DataImg from '../../../src/assets/images/dataNotFound.gif';
import {
  MailOutlined,
  WhatsAppOutlined,
  MessageOutlined,
  ReloadOutlined,
  FileTextOutlined,
  AccountBookFilled,
  MobileOutlined,
} from '@ant-design/icons';
import styled from 'styled-components';

import { retriggerRequest } from '../../services/reimbursementAdmin';
import { toast } from 'react-toastify';

const DetailsContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
`;

export default function CommsModal({ isOpen, commsData, onClose, requestId }) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const [isEmailContent, setIsEmailContent] = useState(false);
  const [retriggerModal, setRetriggerModal] = useState(false);
  const [eventType, setEventType] = useState('');

  const groupedData =
    commsData &&
    commsData?.reduce((groups, item) => {
      const group = groups[item.eventType] || [];
      group.push(item);

      groups[item.eventType] = group;

      return groups;
    }, {});

  const showContentModal = (content) => {
    setIsModalVisible(true);
    setModalContent(content);
  };
  const renderTemplate = (record) => {
    if (record.emailContent) {
      setIsEmailContent(true);
      showContentModal(record.emailContent);
    } else if (record.whatsappContent) {
      setIsEmailContent(false); // Treat WhatsApp content as HTML
      showContentModal(record.whatsappContent);
    } else {
      setIsEmailContent(false);
      showContentModal(record.smsContent);
    }
  };

  const reTriggerModal = (data) => {
    setRetriggerModal(true);
    setEventType(data.eventType);
  };
  const reTriggerComm = async () => {
    const body = { eventType, requestId };
    return await retriggerRequest(body)
      .then(() => {
        toast.success('triggered', { icon: '✅', theme: 'dark' });
        setRetriggerModal(false);
        onClose();
      })
      .catch((err) => {
        console.log({ err });
      });
  };

  const transformedData = Object.entries(groupedData).flatMap(
    ([eventType, items]) =>
      items.map((item, index) => ({
        ...item,
        eventType, // Include the key as a column
        rowSpan: index === 0 ? items.length : 0, // Use rowSpan for the first item in each group
      }))
  );

  const columns = [
    {
      title: 'Event',
      dataIndex: 'eventType',
      render: (text, record) => text, // Display event type
      onCell: (record) => ({
        rowSpan: record.rowSpan, // Group rows
      }),
    },
    {
      title: 'User Type',
      dataIndex: 'userType',
    },
    {
      title: 'Triggered',
      dataIndex: 'triggeredAt',
    },
    {
      title: 'Channel',
      render: (text, record) => (
        <>
          {record.emailContent && (
            <MailOutlined style={{ fontSize: '20px', color: '#ff5722' }} />
          )}
          {record.whatsappContent && (
            <WhatsAppOutlined style={{ fontSize: '20px', color: '#25D366' }} />
          )}
          {record.smsContent && (
            <MobileOutlined style={{ fontSize: '20px', color: '#218aff' }} />
          )}
        </>
      ),
    },

    {
      title: 'Actions',
      render: (text, record) => (
        <Row>
          <Col span={12}>
            <Tooltip title="Retrigger">
              <Button
                icon={<ReloadOutlined />}
                style={{
                  marginRight: '5px',
                  borderRadius: '8px',
                  color: '#3E8DFE',
                }}
                onClick={() => reTriggerModal(record)}
              />
            </Tooltip>
          </Col>
          <Col span={12}>
            <Tooltip title="Content">
              <Button
                icon={<FileTextOutlined />}
                style={{ borderRadius: '8px', color: '#FF855C' }}
                onClick={() => renderTemplate(record)}
              />
            </Tooltip>
          </Col>
        </Row>
      ),
    },
  ];

  return (
    <>
      <Modal
        title={
          <h3 style={{ color: '#714fff', fontWeight: 600 }}>
            Communication Info
          </h3>
        }
        open={isOpen}
        onCancel={onClose}
        footer={null} // Remove footer if you don't need action buttons
        width={'1500px'} // Set custom width (in pixels)
        style={{ height: '400px' }} // Set custom height via style
      >
        <div style={{ display: 'flex', gap: '1rem' }}>
          <div
            style={{ flex: isModalVisible ? 'auto' : '1' }}
            className="custom-table-container"
          >
            <Table
              columns={columns}
              dataSource={transformedData}
              rowKey={(record) => record.commsContentId}
              rowClassName="table-row"
              pagination={false}
              bordered
              scroll={{ x: 'max-content' }}
              style={{
                backgroundColor: '#ffffff',
                borderRadius: '8px',
                boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
              }}
              locale={{
                emptyText: (
                  <div
                    style={{
                      textAlign: 'center',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <img
                      src={DataImg}
                      alt="No data"
                      style={{
                        width: 250,
                        height: 250,
                        marginBottom: 8,
                      }}
                    />
                    <p style={{ color: ' #714fff', fontWeight: 'bold' }}>
                      No Data Available
                    </p>
                  </div>
                ),
              }}
            />
          </div>

          {isModalVisible && (
            <div
              className="shadow-lg"
              style={{
                flex: '2',
                border: '1px solid #714fff',
                borderRadius: '10px',
              }}
            >
              {isEmailContent ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(modalContent),
                  }}
                  style={{
                    borderRadius: '8px',
                    overflowY: 'hidden',
                    padding: '16px',
                  }}
                ></div>
              ) : (
                <div
                  style={{
                    whiteSpace: 'pre-line',
                    padding: '16px',
                    borderRadius: '8px',
                    overflowY: 'hidden',
                  }}
                >
                  {modalContent}
                </div>
              )}
            </div>
          )}
        </div>
      </Modal>
      <Modal
        title="Retrigger Communication"
        open={retriggerModal}
        onCancel={() => setRetriggerModal(false)}
        footer={
          <>
            <Button
              className="custom-hover-button"
              onClick={() => reTriggerComm()}
            >
              Retrigger
            </Button>

            <Button
              className="custom-hover-button float-right"
              onClick={() => setRetriggerModal(false)}
            >
              Cancel
            </Button>
          </>
        } // Remove footer if you don't need action buttons
      >
        <>
          <h3 style={{ fontWeight: '600' }}>
            Are you sure you want to retrigger the communication?
          </h3>
        </>
      </Modal>
    </>
  );
}
